import { useLocation } from "@reach/router"
import parse from "html-react-parser"
import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Fade from "react-reveal/Fade"
import Buttons from "../components/common/button"
import CTA from "../components/common/CTA"
import FeatureCards from "../components/common/Feature-card/FeatureCards"
import Frame from "../components/common/frame"
import PopUpForm from "../components/common/PopUpForm"
import DoubleNavbar from "../components/DoubleNavbar"
import "../styles/pages/ebooks.scss"

const formdata = [
  {
    name: "firstname",
    type: "text",
    label: "First name",
    errorMessage: "Please enter a name",
  },
  {
    name: "email",
    type: "email",
    label: "Email address",
    errorMessage: "Please enter a valid email",
  },
  {
    name: "phone",
    type: "phone",
    label: "Mobile number",
    errorMessage: "Please enter a mobile number",
  },
  {
    name: "interested_for_magazine",
    type: "checkbox",
    required: false,
    choices: ["Tick here if you want to contribute or be featured"],
    data: ["Yes"],
  },
]

const EbookNew = ({ pageContext }) => {
  const { title, seo, newfield } = pageContext.bookdata
  const [isOpen, setIsOpen] = useState(false)
  const [popupVisibility, setPopupVisibility] = useState(false)
  const togglePopup = () => {
    setPopupVisibility(!popupVisibility)
  }
  const toggleIsOpen = () => {
    setIsOpen(prev => !prev)
  }
  const currentLocation = useLocation()
  const path = currentLocation.href
  return (
    <div className="ebooks-2025">
      <Frame bigFooter seo={seo[0]}>
        <DoubleNavbar navType="double-nav" />
        {/* {console.log("newfield", newfield)} */}
        <div>
          {newfield.map((field, ind) => {
            switch (field.__typename) {
              case "SuperOps_Hero": {
                return (
                  <section
                    className="hero-section prelative"
                    style={{
                      backgroundImage: `url(${field.backgroundImage.url})`,
                    }}
                  >
                    <Container className="Layout-container">
                      <Row className="d-flex justify-content-center">
                        <Col lg={6} className="left">
                          <div className="integrate-wrapper d-flex gap-24">
                            <div className="img-wrapper">
                              <img src={field.images[0].url} alt="logo-img" />
                            </div>
                            <div className="img-wrapper">
                              <img src={field.images[1].url} alt="logo-img" />
                            </div>
                            <div className="img-wrapper">
                              <img src={field.images[2].url} alt="logo-img" />
                            </div>
                          </div>
                          <div className="heading-wrapper">
                            {parse(field.heading.html)}
                          </div>
                          <div className="content-wrapper">
                            {parse(field.content.html)}
                          </div>
                          <div className="author-wrapper d-flex gap-24">
                            <div className="author">
                              <div className="img-wrap">
                                <img
                                  src={field.images[3].url}
                                  alt="author-img"
                                />
                              </div>
                              <p className="name">{field.points[0]}</p>
                              <p className="desc">{field.lists[0]}</p>
                            </div>
                            <div className="author">
                              <div className="img-wrap">
                                <img
                                  src={field.images[4].url}
                                  alt="author-img"
                                />
                              </div>
                              <p className="name">{field.points[1]}</p>
                              <p className="desc">{field.lists[1]}</p>
                            </div>
                          </div>
                        </Col>
                        <Col lg={6} className="right prelative">
                          <div className="preview-wrapper d-flex">
                            <img
                              src={field.images[5].url}
                              alt="vector"
                              className="vector"
                            />
                            <button
                              onClick={() => setIsOpen(true)}
                              className="preview-btn"
                            >
                              <img src={field.images[6].url} alt="preview" />
                              PREVIEW NOW
                            </button>
                          </div>
                          <div className="btn-wrapper">
                            <Buttons
                              theme="primary-new down-to-up-4"
                              // link={heroSection?.primaryButtonLink}
                              text="DOWNLOAD EBOOK NOW"
                              onClick={togglePopup}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </section>
                )
              }
              case "SuperOps_Card": {
                if (field.cardType === "Plain_cards") {
                  return (
                    <section className="input-section prelative">
                      <Container className="Layout-container">
                        <div className="heading-wrapper">
                          <h1>{field.cardName}</h1>
                        </div>
                        <div className="input-img-wrapper d-flex gap-24 justify-content-center">
                          {field.image.map((image, index) => (
                            <div className="img-wrap">
                              <img src={image.url} alt="input-img" />
                            </div>
                          ))}
                        </div>
                      </Container>
                    </section>
                  )
                } else if (field.cardType === "Plain_info") {
                  return (
                    <section className="benefit-cards-parent">
                      <div className="Layout-container-big mx-auto">
                        <Fade bottom duration={1000}>
                          <div className="heading-wrapper">
                            {parse(field.content2[0]?.html)}
                          </div>
                        </Fade>

                        <FeatureCards
                          title={field.Heading2}
                          description={field?.text}
                          image={field?.image}
                          //   linkName={field?.subtext}
                          //   link={field?.links}
                          cardsPerRow={3}
                          showBgColorImgOnHover
                        />
                      </div>
                    </section>
                  )
                }
              }
              case "SuperOps_CtaFeature": {
                return (
                  <section className="cta-black-red-stripes">
                    <CTA
                      data={[field.ctaBox]}
                      containerClass
                      lgLeft={6}
                      lgRight={4}
                      newButton
                      isSecondaryBtn
                      newDesign
                      openPopUp={() => togglePopup()}
                    />
                  </section>
                )
              }
            }
          })}
          <div className="form-container">
            <PopUpForm
              visibility={popupVisibility}
              togglePopup={val => {
                setPopupVisibility(val)
              }}
              formData={formdata}
              formName="Ebooks page form"
              endpoint={process.env.HUBSPOT_MAGAZINE_ENDPOINT}
              url={path}
              formTitle={"hello"}
              formButtonText={"download"}
              bottomMessage={"guys"}
              successMsg="thanks for downloading"
              // successBtnText="sucess2"
              downloadFile
              setIsOpen={toggleIsOpen}
            />
          </div>
          {isOpen && (
            <div className="modal-overlay">
              <div className="modal-content">
                <button onClick={() => setIsOpen(false)} className="close-btn">
                  ✕
                </button>
                <div className="iframe-container">
                  <iframe
                    src="https://online.fliphtml5.com/vxrxg/mvqe/"
                    seamless="seamless"
                    scrolling="no"
                    frameBorder="0"
                    allowTransparency="true"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          )}
        </div>
      </Frame>
    </div>
  )
}

export default EbookNew
